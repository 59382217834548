import footerLabels from "./footerCMSLabels";
import townThemeManagerLabels from "./townThemeManagerLabels";

const cmsLabels = {
  ...footerLabels,
  ...townThemeManagerLabels,
  MENU_MANAGER: "Menu Manager",
  SAVE_MENU: "Save Menu",
  ADD_PRIMARY_MENU_ITEM: "Add Menu Item",
  ADD_SECONDARY_MENU_ITEM: "Add Secondary Menu Item",
  ADD_SECONDARY_CUSTOM_ITEM: "Add Secondary Custom Item",
  YOU_CANNOT_ADD_SUB_MENU_ITEMS: "You cannot add sublevel items to this option",
  MENU_SAVED: "Menu was successfully saved.",
  MENU_NOT_SAVED: "Failure while saving menu. Please try again.",
  UPDATE_ARTICLE: "Update Article",
  ARTICLE_BEING_EDITED_MESSAGE:
    "The article is being edited by someone else. Please backup your changes and come back to the article later.",
  DRAFT_ARTICLE_SAVED: "Content successfully autosaved. ",
  DRAFT_ARTICLE_NOT_SAVED_ERROR:
    "Autosave Error:  Headline or Body Text is missing.  Please fill in headline or body text to enable Autosave to function. ",
  AUTOSAVE_ENABLED: "Autosave is enabled.",
  AUTOSAVE_DISABLED:
    'Autosave is disabled.  Select "Save as Draft" to enable Autosave functionality.',
  FACEBOOK_PHOTO_SUCCESSFULLY_UPLOADED: "Facebook photo successfully uploaded.",
  FACEBOOK_PHOTO_INVALID_SIZE:
    "Image size not supported. Must be 1200x630 px. Please select a valid image.",
  FACEBOOK_PHOTO_INVALID_TYPE:
    "Image type is not supported. Please select a valid extension image (png|jpg|jpeg|webp).",
  UNEXPECTED_ERROR_FACEBOOK_PHOTO:
    "An error has occured. Please try again later.",
  FACEBOOK_PHOTO_SELECT_IMAGE: "Select the image by clicking on Choose File.",
  FACEBOOK_PHOTO_SELECT_IMAGE_SIZE:
    "For best results, use images that are at least 1200 pixels (width) x 630 pixels (height).",
  FACEBOOK_PHOTO_SMALLER_IMAGE_SIZE:
    "If your image is smaller than 600 pixels (width) x 315 pixels (height), it will still display in the post, but the thumbnail will be smaller and positioned to the left.",
  FACEBOOK_PHOTO_SELECT_IMAGE_RESIZE_1: "To quickly resize your photo, use ",
  FACEBOOK_PHOTO_SELECT_IMAGE_RESIZE_2: "Pixlr.",
  REMOVE_PHOTO: "Remove photo",
  SAVE_FACEBOOK_PHOTO: "Save facebook photo",
  SHOW_TAPINTO_LOGO_IN_FOOTER: "Show TAPinto logo in footer",
  UPLOAD_YOUR_EXIT_INTENT_PHOTO: "Upload your exit intent pop-up photo:",
  DRAG_AND_DROP_PHOTOS_INSTRUCTIONS:
    "Drag and drop your file(s) here, or click Browse files to select files(s) from your computer.",
  UPLOAD_PHOTO_INSTRUCTIONS: {
    size_and_format:
      "Photos must be less than 20MB and in GIF, JPEG, or PNG format.",
    ratio_and_orientation:
      "For best results, please use photos in landscape orientation with a 3:2 aspect ratio (for example, 1200 width x 800 height).",
    pixlr: "To quickly resize your photos, use Pixlr.",
    credits: "Photo credits must be applied to all photos.",
  },
  UPLOAD_VIDEO_INSTRUCTIONS: {
    size_and_format: "Videos must be less than 100MB and in MP4 or MOV format.",
    ratio_and_orientation:
      "For best results, please use videos in landscape orientation with a 16:9 aspect ratio.",
    credits: "Video credits must be applied to all videos.",
  },
  EDIT_PHOTO: {
    preview_options: "Photo Preview options",
    photo_in_carousel: "Include photo in carousel",
    embed_photo_in_text: "You can also embed this photo in the text",
    copy_hyperlink: "Copy image url and then hyperlink your text",
    copy_html: "Copy HTML, then use the HTML button in your editor",
  },
  EDIT_VIDEO: {
    preview_options: "Video Preview options",
    video_in_carousel: "Include video in carousel",
    embed_video_in_text: "You can also embed this video in the text",
    copy_hyperlink: "Copy video url and then hyperlink your text",
    copy_html: "Copy HTML, then use the HTML button in your editor",
  },
  VIDEO_UPLOADER: {
    deleted_videos: "Your videos were successfully deleted",
    uploaded_videos: "Your videos were successfully uploaded",
    error_uploading_videos:
      "There was an error while uploading the videos, please check the file size and format.",
    delete_bulk_message: "Are you sure you want to delete all selected items?",
    validate_bulk_items:
      "Please select at least one item to perform bulk action",
  },
  ADD_CUSTOM_MENU_ITEM: "Add Custom Menu Item",
  ENABLE_GAMES_SECTION: "Enable Games Section",
  DISABLE_GAMES_SECTION: "Disable Games Section",
  TEMPLATES: "Templates",
  GENERAL_SETTINGS: "General settings",
};

export default cmsLabels;
